import BpkBannerAlert, {
  ALERT_TYPES,
} from '@skyscanner/backpack-web/bpk-component-banner-alert';

import type { DisclaimerProps } from 'common-types/types/DisclaimerProps';

import STYLES from './FooterBlurb.module.scss';

const createMarkup = (markup: string) => ({ __html: markup });
const FooterBlurb = ({ disclaimerCopy }: DisclaimerProps) => (
  <BpkBannerAlert
    bannerClassName={STYLES.FooterBlurb__disclaimer}
    // eslint-disable-next-line react/no-danger
    message={<span dangerouslySetInnerHTML={createMarkup(disclaimerCopy)} />}
    type={ALERT_TYPES.NEUTRAL}
  />
);

export default FooterBlurb;
